import React, { } from 'react';
import {
  ResumeName, ResumeContact, ResumeEducation, ResumeSkills, ResumeSummary,
  ResumeWorkHistory, ResumeVolunteer, ResumeCertifications,
} from '@brianwhaley/pixelated-components/dist/index';
import '@brianwhaley/pixelated-components/dist/components/resume/pixelated.resume.css';
import ResumeData from '../data/resume.json';

export default function MyResume() {
  return (
    <section className="p-resume" id="resume-section">
      <div className="section-container">

        <div className="p-name grid12">
          <ResumeName data={ResumeData} />
        </div>

        <div className="grid3 bigpad divider">
          <div className="p-contact">
            <ResumeContact data={ResumeData} />
          </div>
          <div className="p-education">
            <ResumeEducation data={ResumeData} dateFormat="yyyy" showDate={false} />
          </div>
          <div className="p-skills">
            <ResumeSkills data={ResumeData} />
          </div>
        </div>

        <div className="grid9 bigpad">
          <ResumeSummary data={ResumeData} />
          <ResumeWorkHistory data={ResumeData} dateFormat="yyyy" showDate />
          <ResumeVolunteer data={ResumeData} dateFormat="yyyy" showDate />
          <ResumeCertifications data={ResumeData} dateFormat="yyyy" showDate={false} />
        </div>
      </div>
    </section>

  );
}
