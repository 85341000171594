import React, { } from 'react';

export default function Home() {
  return (
    <div>
      Global Corporations need Competitive Intelligence (CI) to advance their business strategies.
      Information Focus has over 30 years of experience in building large, mid-size,
      and one-person CI units.
      <br />
      <br />
      <img src="/images/mas.jpg" className="mas" alt="Mary Ann Sarao" />
      Mary Ann Sarao is an expert in establishing and managing Corporate CI Units in the areas of:
      Pharmaceuticals and Therapeutic Areas, Health Care, Medical Devices, Consumer Health,
      Manufacturing, Regulatory, R&amp;D, Corporate Global Security Platforms,
      Anti-Counterfeiting, Brand Protection, Investor Relations, and Communications.
      <br />
      <br />
      <div className="quote">
        “Good CI can be done within legal and ethical guidelines.
        Information Focus will help guide you every step of the way to building
        a valuable and sustainable CI unit with the right people,
        processes, and technologies.”
        {' '}
        <br />
        <b>Mary Ann Sarao, Principal Information Focus</b>
      </div>
      <br />
      <br />
      Capabilities
      <ul>
        <li>Building a New CI Unit from the Ground Up</li>
        <li>Corporate CI Policies & Guidelines</li>
        <li>
          Developing Corporate CI Training Programs and
          Counterintelligence Programs for Employees
        </li>
        <li>CI Department Resource Development & Budget </li>
        <li>Re-branding Established CI Units</li>
        <li>Auditing Established CI Units for Gaps</li>
        <li>Recommendations for New CI Platforms Across Your Organization/AI &amp; NLP</li>
        <li>Team Training on Corporate War Gaming Events/Scenario Simulations</li>
        <li>CI Leadership Mentorship &amp; Metrics</li>
        <li>CI Consulting with Corporate Legal Departments</li>
        <li>Expert Network Consulting</li>
        <li>
          Experience with: Pfizer, Johnson &amp; Johnson, Merck,
          Bristol-Myers Squibb, Bayer, and DSM
        </li>
      </ul>
      <br />
      <br />
      <div className="logo-row">
        <img src="/images/logo-pfizer.png" alt="Pfizer" />
        <img src="/images/logo-j&j.svg.png" alt="Johnson & Johnson" />
        <img src="/images/logo-merck.png" alt="Merck" />
        <img src="/images/logo-bms.svg.png" alt="Bristol-Myers Squibb" />
        <img src="/images/logo-bayer.svg.png" alt="Bayer" />
        <img src="/images/logo-dsm.svg.png" alt="DSM" />
      </div>
    </div>
  );
}
