import React, { } from 'react';
import { MenuSimple } from '@brianwhaley/pixelated-components/dist/index';
import '@brianwhaley/pixelated-components/dist/components/menu/pixelated.menu-simple.css';

export default function Nav() {
  const menuItems = {
    Home: '/index.html',
    Resume: '/resume.html',
  };

  return (
    <div>
      <MenuSimple menuItems={menuItems} ref={(myMenu) => { window.myMenu = myMenu; }} />
    </div>
  );
}
