import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Nav from './pages/nav';
import Header from './pages/header';
import Footer from './pages/footer';

import Home from './pages/home';
import MyResume from './pages/resume';
import NotFound from './pages/notfound';

function App() {
  return (
    <>

      <header>
        <Header />
      </header>

      <nav>
        <Nav />
      </nav>

      <main>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/index.html" element={<Home />} />
            <Route path="/resume.html" element={<MyResume />} />
            <Route exact path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </main>

      <footer>
        <Footer />
      </footer>

    </>
  );
}
export default App;
