import React, { } from 'react';

export default function Header() {
  return (
    <div>
      <div className="logo"><img src="/images/informationfocus.png" className="logo" alt="InformationFocus" /></div>
      <div className="title"><h1>InformationFocus</h1></div>
      <div className="contactinfo">
        Mary Ann Sarao, Principal
        <br />
        maryann.sarao@gmail.com
      </div>
    </div>
  );
}
